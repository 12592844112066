import React from 'react';
import wechat from 'common/src/assets/image/interior/wechat.jpg';
import SEO from '../components/seo';

const divStyle = {
  fontSize: '18px'
};

const BP = () => (
  <div style={divStyle}>
    <SEO title="Business Plan" />
    <h1>Business Plan</h1>

    <h3>Executive Summary </h3>

    <p>
      Name a few of the most frequently used in our daily life, chair is definitely on top of the list. But it is also the most neglected in digital world. What we use today is no changes to what it was one hundred years ago. Time to create something different!
      
      <br/><br/>
    
      Entertainment industry is a huge market, including movies, music and especially video games (~200B in revenue in 2020 according to Routers). COVID-19 pandemic tremendously drove people to spend more time on them at home. The blooming industry still has very wide room for growth, as young generation grow up with electronic devices. 
      
      <br/><br/>

      People love immersive experiences. 3D/4D movies and rides are the most popular ones in cinemas and theme parks. AR/VR are gaining more and more popularity on the same purpose. 
      
      <br/><br/>

      Now, we combine the above together and bring the ultimate entertainment experience to home, by the immersive chair with full-body haptic feedback. 3-DOF (degree of freedom) of Pitch/Roll/Heave, and multiple independent vibration motors create such an immersion to enjoy like never before. We will provide content service of streaming, video games and third-party apps, all of which will work interactively with the chair by integrating Feelsit SDK, wirelessly from any devices. At the same time, the chair will have massage and meditation programs for relaxing. Last but not least, it has a stunning design - even without these entertainment features, it is an eye candy fitting your modern home.

    </p>{' '}

    <h3>The Problem</h3>
    <p>
      <ul>
        <li>Movies: to get the immersive experiences nowadays, people have to go to movie theaters with D-Box chairs of extra $10 surcharge. There are very few of them and only in big cities and the supported movies are very limited.</li>
        <li>Video Games: currently on the market, the 3-DOF or 6-DOF simulator is expensive (price range: $5k ~ $10K) and only supports PC racing/flight games. It can do nothing else and merely is for game enthusiasts.</li>
        <li>Massage Chair: many families have massage chair (price range: $2k ~ $20k), but it is bulky and less frequently used. Even massage chair makers recommend use only 1-3 times a week.</li>
      </ul>
    </p>
    {' '}

    <h3>Our Solution</h3>
    <p>

      Feelsit is an immersive experience platform, including the frontend electronic chair (aka "The Chair" in context) with affordable price ($5k ~ $8k), the backend content service of subscription model, as well as mobile app and web app.

      <ul>
        <li>Movies: We will provide streaming service like Netflix so that end user can watch movies on any devices. We've developed movie motion code editor to embed the fine-tuned motions, and end user will feel 3-DOF (Pitch/Roll/Heave) and different level of vibrations across the body sitting in "The Chair", while watching the movie.</li>
        <li>Video Games: We will provide easy-to-use Feelsit SDK to integrate with existing or new video games. Besides 3-DOF and the independent full-body vibration motors, we will also sell plug-n-play accessories (such as joystick, driving wheel and pedals), to install onto "The Chair". Given these, all types of game experience will be greatly enhanced, no matter it is Simulation, FPS or Sports game. The possibilities are infinite.</li>
        <li>Massage Chair: We will provide various massage and meditation programs which can be updated on the fly.</li>
        <li>Feelsit App Store: We will build app store to give 3rd party developers ability to utilize the chair, like turning it into baby swing, or customized motion programs.</li>
      </ul>
    </p>
    {' '}

    <h3>The Customer</h3>
    <p>The target customers are middle-class family, who like watching movies or playing video games. It overlaps with streaming service customers like Netflix, and attractive to most of massage chair buyers as the price is similar but has many more features.</p>
    {' '}

    <h3>Pitch Video</h3>
    <ul>
      <li>Youtube: <a href="https://youtu.be/ZPQF81pGwMs" target="_blank" rel="noopener noreferrer">https://youtu.be/ZPQF81pGwMs</a></li>
      <li>or, Youku: <a href="https://v.youku.com/v_show/id_XNTEzNzQwMjk2MA==.html"  target="_blank" rel="noopener noreferrer">https://v.youku.com/v_show/id_XNTEzNzQwMjk2MA==.html</a></li>
    </ul>
    {' '}

    <h3>Business Model</h3>
    <ol>
      <li>Hardware: Sell "The Chair" and accessories directly to customers online, like Tesla.com.</li>
      <li>Software: Subscription model for content.</li>
    </ol>
    {' '}

    <h3>Where We Are</h3>
    <p>We are done with Prototype and working hard on Production. 
      <br/>
      Concpet ✅ --> Prototype 🟢 --> Production --> Ship</p>
    {' '}
    
    
    <h3>Main Competitor</h3>
    <p>
    <a href="https://www.d-box.com/" target="_blank" rel="noopener noreferrer">D-Box</a> is a Canadian company who sells expensive (> $50k) movie theater chair, and mainly partners with cinemas. You can find 3rd party dealers to build home theatres or game simulators but it is way expensive.
    </p>{' '}
    
    <h3>Company Description</h3>
    <ul>
      <li>Business Name: <a href="https://www.feelsit.com/" target="_blank" rel="noopener noreferrer">Feelsit Inc.</a></li>
      <li>Legal structure: C Corp registered in Delaware, US</li>
      <li>Ownership: Li Sun 80%, reserved 20%</li>
    </ul>
    {' '}

    <h3>The Team </h3>
    <ul>
      <li>US: Li Sun, Founder & CEO of <a href="https://www.feelsit.com/" target="_blank" rel="noopener noreferrer">Feelsit Inc.</a>, San Jose, CA - <a href="https://www.linkedin.com/in/li-sun-6a25077/" target="_blank" rel="noopener noreferrer">LinkedIn Profile</a></li>
      <li>Business Partners in China: <a href="http://www.umasoft.com/" target="_blank" rel="noopener noreferrer">Uma Software</a> (Nanjing, Jiangsu) <a href="http://www.evergear.com.cn/" target="_blank" rel="noopener noreferrer">EverGear</a> (Wenzhou, Zhejiang)</li>
    </ul>
    {' '}


    <h3>Milestones</h3>
    <ol>
      <li>Build up "The Chair", content platform and content partners.</li>
      <li>Build up more immersive products and the ecosystems.</li>
    </ol>
    {' '}

    <h3>Fundraising</h3>
    <p>Seeking $1M for 12-month of operations to reach Milestone #1, in exchange for 25% of equity in the company of Feelsit Inc.</p>
    {' '}


    <h3>Contact</h3>
    <ul>
      <li>Email: <a href="mailto:li@feelsit.com">li@feelsit.com</a></li>
      <li>Wechat: </li>
      <img src={wechat} />
    </ul>
    {' '}

  </div>
);

export default BP;
